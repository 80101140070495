//  引入拦截器
import axios from '@/common/js/request';

export default {
    //  获取看压强度试验记录列表
    getStrength(params) {
        return axios({
            url:'/outside/getStrength',
            method:'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            params
        })
    },
    //  获取看压气密试验记录列表
    getAirtight(params) {
        return axios({
            url:'/outside/getAirtight',
            method:'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            params
        })
    },
     //  获取看压吹扫试验记录列表
     getPurge(params) {
        return axios({
            url:'/outside/getPurge',
            method:'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            params
        })
    },
     getIndoor(params) {
        return axios({
            url:'/outside/getIndoor',
            method:'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            params
        })
    },
    getIndoorRectify(params) {
        return axios({
            url:'/outside/getIndoorRectify',
            method:'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            params
        })
    },
    rectifyList(params) {
        return axios({
            url:'/accept/rectifyList',
            method:'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            params
        })
    },
    // pc看压验收列表
    getAcceptance(params) {
        return axios({
            url:'/outside/getAcceptance',
            method:'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            params
        })
    },
    // 看压验收列表-验收信息
    getAcceptanceInfos(params) {
        return axios({
            url:'/outside/getAcceptanceInfos',
            method:'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            params
        })
    },
    // 验收信息-环境判定列表
    getEnvironment(params) {
        return axios({
            url:'/outside/getEnvironment',
            method:'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            params
        })
    },
    // 验收详情
    checkDetails(params) {
        return axios({
            url:'/accept/checkDetails',
            method:'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            params
        })
    },
}