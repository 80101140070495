<template>
  <div>
    <div class="content1">
      <div class="all">
        <div ref="SearchBar" class="SearchBar" style="padding-left: 20px">
          工程编号/名称:
          <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.searchText"
          ></el-input>
          <span style="margin-right: 20px"></span>
          安装施工队:
          <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.install"
          ></el-input>
          <span style="margin-right: 20px"></span>
          现场管理员:
          <el-input
            style="width: 200px"
            placeholder="请输入"
            size="small"
            v-model="searchBox.scene"
          ></el-input>
          <span style="margin-right: 20px"></span>
          <el-button
            type="primary"
            class="SearchBtn"
            size="small"
            @click="search"
            >查询</el-button
          >
          <el-button
            type="primary"
            class="SearchBtn"
            size="small"
            @click="resetData"
            >重置</el-button
          >
        </div>
        <div class="table">
          <!--数据表格-->
          <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%; margin: 15px 0"
            border
            height="calc(100vh - 350px)"
            :stripe="true"
            :default-sort="{ prop: 'createTime', order: 'descending' }"
          >
            <el-table-column
              type="index"
              label="序号"
              width="70"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="projectName"
              label="工程名称"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="siteManager"
              label="现场管理员"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="realname"
              label="安装施工队"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="isQualified"
              label="敷设环境"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <div v-if="row.isRadiation == 0">-</div>
                <div v-else-if="row.isRadiation == 1 && row.workRadiationAcceptList.length == 0">未验收</div>
                <el-tag v-else :type="row.isQualified ? 'success' : 'danger'">{{
                  row.isQualified ? "合格" : "不合格"
                }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="isQualified1"
              label="地面标志物"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <div v-if="row.isMarker == 0">-</div>
                <div v-else-if="row.isMarker == 1 && row.workStructuresAcceptList.length == 0">未验收</div>
                <el-tag v-else :type="row.isQualified1 ? 'success' : 'danger'">{{
                  row.isQualified1 ? "合格" : "不合格"
                }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="facility"
              label="重点设施"
              align="center"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column label="操作" align="left" show-overflow-tooltip>
              <template slot-scope="{ row }">
                <el-button type="primary" size="small" @click="seeDetil(row)"
                  >查看详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="pageBox">
            <el-pagination
              :current-page="searchBox.current"
              :background="true"
              :page-sizes="[30, 50, 100]"
              :page-size="searchBox.size"
              layout="total, prev, pager, next, sizes, jumper"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="主管线验收详情"
      :visible.sync="detilDialog"
      width="45%"
      :close-on-click-modal="false"
    >
      <el-tabs v-if="detilDialog" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          :label="item.name"
          :name="item.value"
          v-for="(item, index) in project.tabs"
          :key="index"
        >
          <!--详情数据表格-->
          <el-table
            ref="tableData"
            :data="rowData[activeName]"
            style="width: 100%; margin: 15px 0"
            border
            :stripe="true"
            :default-sort="{ prop: 'createTime', order: 'descending' }"
          >
            <el-table-column
              type="index"
              label="序号"
              width="70"
              align="center"
            >
            </el-table-column>
            <el-table-column
              v-if="activeName != 'workFacilityAcceptList'"
              prop="isQualified"
              label="验收结果"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <div v-if="row.isQualified === true" style="cursor: pointer">
                  合格
                </div>
                <div
                  v-else-if="row.isQualified === false"
                  style="cursor: pointer"
                >
                  不合格
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column
                v-if="activeName != 'workFacilityAcceptList'"
                prop="img"
                label="现场照片"
                align="center"
                show-overflow-tooltip
            >
              <template slot-scope="{row}">
                <div v-if="row.img==''||row.img==null">暂无图片</div>
                <el-image
                    v-else
                    style="width: 80px; height: 80px"
                    :src="row.img.split(',')[0]"
                    :preview-src-list="row.img.split(',')">
                </el-image>
              </template>
            </el-table-column>
            <el-table-column
              v-if="activeName != 'workFacilityAcceptList'"
              prop="realname"
              label="验收人"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              v-if="activeName != 'workFacilityAcceptList'"
              prop="contractCode"
              label="整改情况"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <div
                  v-if="!row.isQualified"
                  style="cursor: pointer"
                  @click="examine(row, activeName)"
                >
                  查看
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="activeName == 'workFacilityAcceptList'"
              prop="typeName"
              label="重点设施"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              v-if="activeName == 'workFacilityAcceptList'"
              prop="location"
              label="经纬度"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              v-if="activeName == 'workFacilityAcceptList'"
              prop="img"
              label="重点设施照片"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <div
                  v-if="row.img"
                  @click="showImg(row.img)"
                  style="cursor: pointer"
                >
                  查看
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="activeName == 'workRadiationAcceptList'"
              prop="contractCode"
              label="重新看压"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <div v-if="row.anew === true" style="cursor: pointer">是</div>
                <div v-else-if="row.anew === false" style="cursor: pointer">
                  否
                </div>
                <div v-else>-</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="createTime"
              label="提交时间"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
            <el-table-column
              prop="createBy"
              label="提交人"
              align="center"
              show-overflow-tooltip
            ></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <el-dialog
      :visible.sync="examineDialog"
      width="480px"
      :close-on-click-modal="false"
    >
      <el-form
        ref="project"
        v-if="examineDialog"
        :model="project"
        label-width="120px"
        size="mini"
      >
        <div style="max-height: 600px; overflow: auto" v-if="condition">
          <p class="detil-code">整改通知</p>
          <div style="padding: 10px">
            <div class="item-list">
              整改原因： {{ rectificationInof.cause }}
            </div>
            <div class="item-list">
              <div style="margin-bottom: 10px">不合格照片：</div>
              <div>
                <el-image
                  v-if="rectificationInof.img"
                  style="width: 100px; height: 100px"
                  :src="rectificationInof.img.split(',')[0]"
                  :preview-src-list="rectificationInof.img.split(',')"
                >
                </el-image>
                <span style="margin-left: 10px" v-if="rectificationInof.img"
                  >共{{ rectificationInof.img.split(",").length }}张</span
                >
              </div>
            </div>
          </div>
        </div>
        <div style="flex: 1" >
                <p class="detil-code">整改处理</p>
                <div style="padding: 10px">
                  <div class="item-list">
                    处理结果： {{ state[rectificationInof.state] }}
                  </div>
                  <div class="item-list">
              <div style="margin-bottom: 10px" v-if="rectificationInof.state == 2">相关证据：</div>
                  <div>
                    <el-image
                      v-if="rectificationInof.explainImg"
                      style="width: 100px; height: 100px"
                      :src="rectificationInof.explainImg.split(',')[0]"
                      :preview-src-list="rectificationInof.explainImg.split(',')"
                    >
                    </el-image>
                    <span style="margin-left: 10px" v-if="rectificationInof.explainImg"
                      >共{{ rectificationInof.explainImg.split(",").length }}张</span
                    >
                  </div>
                </div>
                  <div class="item-list" v-if="rectificationInof.teams">施工队： {{ rectificationInof.teams.join('；') }}</div>
                  <div class="item-list">操作人： {{ rectificationInof.updateBy }}</div>
                  <div class="item-list">操作时间： {{ rectificationInof.updateTime }}</div>
                  
                
                </div>
              </div>
        <div v-if="rectificationInof.state != 2">
          <p class="detil-code">整改情况</p>
          <div
            v-if="rectificationTable.length"
            style="
              padding: 10px;
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
            "
          >
            <div
              class="item-list"
              style="flex: 0 0 200px"
              v-for="item in rectificationTable"
              :key="item.id"
            >
            <div class="item-list">整改施工队： {{ item.workTeamName }}</div>
              <div class="item-list">
                <div style="margin-bottom: 10px">整改后情况：</div>
                <div>
                  <el-image
                    v-if="item.laterImg"
                    style="width: 100px; height: 100px"
                    :src="item.laterImg.split(',')[0]"
                    :preview-src-list="item.laterImg.split(',')"
                  >
                  </el-image>
                  <span style="margin-left: 10px" v-if="item.laterImg"
                    >共{{ item.laterImg.split(",").length }}张</span
                  >
                </div>
              </div>
              <div class="item-list">整改说明： {{ item.explain }}</div>
            </div>
          </div>
          <div style="padding: 20px 10px;" v-else>暂无整改信息</div>
        </div>
      </el-form>
    </el-dialog>
    <show-img :imgList="imgList" ref="imgList"></show-img>
  </div>
</template>

<script>
import axiosApi from "@/apis/checkOuter/index.js";
import showImg from "@/components/showImg.vue";
export default {
  components: { showImg },
  name: "lockout",
  data() {
    return {
      status: "12",
      activeName: "workRadiationAcceptList",
      condition: true,
      imgList: [],
      project: {
        tabs: [
          { name: "敷设环境", value: "workRadiationAcceptList", start: true },
          {
            name: "地面标志物",
            value: "workStructuresAcceptList",
            start: false,
          },
          { name: "重点设施", value: "workFacilityAcceptList", start: false },
        ],
      },
      state: {
        3: "已整改",
        2: "不整改",
        1: "进行整改",
        0: "未整改",
      },
      searchBox: {
        current: 1,
        size: 100,
      },
      total: 0,
      detilDialog: false,
      examineDialog: false,
      rowData: null,
      typeList: [
        {
          label: "合格",
          value: 1,
        },
        {
          label: "不合格",
          value: 3,
        },
        {
          label: "未出结果",
          value: 2,
        },
      ],
      tableData: [],

      rectificationInof: {},
      rectificationTable: [],
    };
  },
  mounted() {
    this.loadList(this.searchBox);
  },
  methods: {
    showImg(img) {
      console.log(img.split(","));
      this.imgList = img.split(",");
      this.$refs.imgList.setdialogVisible(true);
    },
    handleClick(val, e) {
      this.activeName = val.name;
      // 调接口获取列表替换
    },
    async examine(row, name) {
      let obj = {
        id: row.id,
        source: null,
      };
      if (name == "workRadiationAcceptList") {
        obj.source = 3;
      }
      if (name == "workStructuresAcceptList") {
        obj.source = 4;
      }
      try {
        let { data } = await axiosApi.getIndoorRectify(obj);
        if (data.length) {
          this.rectificationInof = data[0];
          this.rectificationTable = data[0].workInspectionSgdSubsetList;
        }
      } catch (error) {}
      this.examineDialog = true;
    },
    async seeDetil(row) {
      this.rowData = row;
      this.detilDialog = true;
    },
    handleSizeChange(val) {
      this.searchBox.current = 1;
      this.searchBox.size = val;
      this.loadList(this.searchBox);
    },
    handleCurrentChange(val) {
      this.searchBox.current = val;
      this.loadList(this.searchBox);
    },
    search() {
      this.searchBox.current = 1;
      this.loadList(this.searchBox);
    },
    async loadList(obj) {
      try {
        let { data } = await axiosApi.getIndoor(obj);
        this.tableData = data.records;
        this.total = data.total;
      } catch (error) {}
    },
    resetData() {
      this.searchBox = {
        current: 1,
        size: 100,
      };
      this.loadList(this.searchBox);
    },
  },
};
</script>

<style scoped>
.detil-code {
  padding-left: 10px;
  font-size: 16px;
  height: 16px;
  line-height: 16px;
  color: #000000;
  position: relative;
  margin-bottom: 15px;
}
.detil-code:before {
  content: "";
  position: absolute;
  width: 3px;
  height: 16px;
  background-color: #007aff;
  top: 0;
  left: 0;
}
.content {
  background: #f3f4f8;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  padding-bottom: 1%;
  background-color: #ffffff;
}
.SearchBtn {
  margin-left: 10px;
}
.table {
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
}
.item-list {
  margin-bottom: 24px;
}
</style>